
@media screen and (min-width: 768px) {
	.official {
		position: relative;
	}
	.official-navbar {
		z-index: 9;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 70px;
		background-color: rgba(255, 255, 255, 0);
	}
	
	.official-navbar-logo {
		position: absolute;
		left: 18.75%;
		top: 22px;
		width: 182px;
		height: 26px;
		background-image: url(../../../assets/sys/official/top_logo_red.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}

	.official-navbar-up {
		.official-navbar-logo {
			background-image: url(../../../assets/sys/official/top_logo.png);
		}
	}
	
	.official-navbar-tabbar {
		position: absolute;
		right: 18.75%;
		top: 20px;
		>a {
			display: inline-block;
			min-width: 30px;
			margin-left: 50px;
			font-size: 16px;
			color: #fff;
			cursor: pointer;
		}
		>a:first-child {
			margin-left: 0;
		}
		.tabbar-slide {
			position: absolute;
			left: 0;
			bottom: -10px;
			width: 30px;
			height: 2px;
			transition: transform .2s;
		}
	
		.tabbar-slide-up {
			background-color: #fff;
		}
	
		.tabbar-slide-next {
			background-color: #0053FF;
		}
		.tabItem-active {
			color: #0053FF;
		}
		
		.tabItem {
			color: #333;
		}
		
		.tabItem-top-up {
			color: #fff;
		}
	}

	.official-navbar-menu {
		display: none;
	}
	
	.official-navbar-next {
		background-color: #fff;
	}
	
	.official-header {
		position: relative;
		height: 600px;
		background-color: #7CBAC7;
		background: url(../../../assets/sys/official/banner.png);
		background-repeat: no-repeat;
		background-size: contain;
		background-size: 100% 100%;
		.official-header-title {
			z-index: 2;
			position: absolute;
			top: 223px;
			left: 0;
			right: 0;
			text-align: center;
			font-size: 60px;
			font-weight: 400;
			color: #fff;
		}
		.official-header-subTitle {
			z-index: 2;
			position: absolute;
			top: 321px;
			left: 0;
			right: 0;
			text-align: center;
			font-size: 36px;
			font-weight: 300;
			color: #fff;
		}
		.official-header-mask {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}
	}
	
	.official-about {
		height: 577px;
		position: relative;
		background-color: #fff;
		.watermark {
			padding-top: 62px;
			font-size: 70px;
			top: 0;
			font-weight: bold;
			text-align: center;
			color: #F5F5F5;
		}
		// .title {
		// 	position: absolute;
		// 	top: 80px;
		// 	left: 0;
		// 	right: 0;
		// 	font-size: 40px;
		// 	text-align: center;
		// 	color: #333;
	
			
		// }
		.content {
			position: absolute;
			left: 0;
			right: 0;
			top: 177px;
			text-align: center;
			color: #666;
			.aboutList {
				.aboutItem {
					display: inline-block;
					position: relative;
					width: 270px;
					height: 300px;
					margin-right: 40px;
					border-radius: 10px;
					transition: all .2s;
					cursor: pointer;
					.aboutItem-image {
						display: block;
						width: 114px;
						height: 104px;
						margin: 34px auto 0;
					}
					.aboutItem-title {
						position: absolute;
						left: 0;
						right: 0;
						top: 177px;
						font-size: 18px;
						text-align: center;
						line-height: 18px;
						font-weight: bold;
						color: #242831;
					}
					.aboutItem-subTitle {
						position: absolute;
						left: 20px;
						right: 20px;
						top: 215px;
						font-size: 14px;
						text-align: center;
						color: #535A64;
					}
				}
				.aboutItem:last-child {
					margin-right: 0;
				}
	
				.aboutItem:hover {
					box-shadow: 0px 20px 40px 0px rgba(0,83,255,0.1);
				}
			}
		}
	}
	
	.watermark {
		position: absolute;
		left: 0;
		right: 0;
		top: 45px;
		text-align: center;
		font-size: 70px;
		font-weight: bold;
		color: #EEEEEE;
	
	}
	.title {
		position: absolute;
		left: 0;
		right: 0;
		top: 80px;
		font-size: 40px;
		text-align: center;
		color: #333;
	}
	
	.official-advantage {
		position: relative;
		height: 716px;
		background-color: #F4F5F7;
		.content {
			position: absolute;
			left: 0;
			right: 0;
			top: 226px;
			width: 1020px;
			margin: 0 auto;
		}
		.mySwiper {
			float: left;
			width: 800px;
		}
		.swiper-slide {
			position: relative;
			width: 310px;
			height: 390px;
			background-position: center;
			background-size: cover;
		}
	
		.swiper-slide img {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			display: block;
			width: 200px;
			height: 252px;
			//transform: scale(0.645, 0.795);
			transition: all .3s;
		}
		.swiper-slide span {
			position: absolute;
			left: 20px;
			top: 20px;
			color: #333;
			font-size: 20px;
		}
		.swiper-slide-active img {
			width: 100%;
			height: 100%;
			//transform: scale(1);
		}
		.swiper-slide-watermark {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			margin: auto;
			display: block;
			width: 200px;
			height: 252px;
			background-color: rgb(255, 255, 255, .5);
			transition: all .3s;
		}
		.swiper-slide-active .swiper-slide-watermark {
			width: 100%;
			height: 100%;
			background-color: rgb(255, 255, 255, 0);
		}
	
		.control {
			max-width: 176px;
			width: 176px;
			margin-right: 40px;
			padding-top: 62px;
			float: left;
		}
	
		.control-page {
			>span:nth-child(1) {
				font-size: 60px;
				line-height: 55px;
				font-family: Bebas;
				color: #0053FF;
			}
			>span:nth-child(2) {
				margin-left: 15px;
				font-size: 40px;
				line-height: 40px;
				font-family: Bebas;
				color: #C3C4C6;
			}
		}
	
		.control-title {
			margin-top: 49px;
			font-size: 32px;
			line-height: 30px;
			color: #333;
		}
		.control-subTitle {
			margin-top: 24px;
			font-size: 16px;
			line-height: 16px;
			color: #666;
		}
		.control-content {
			position: absolute;
			bottom: 62px;
			>div {
				float: left;
				width: 32px;
				height: 32px;
				border-radius: 50%;
				border: 1px solid #707172;
				cursor: pointer;
			}
			>div:hover {
				border-color: #E4E6E8;
				background-color: #E4E6E8;
			}
			.control-prev {
				margin-right: 20px;
			}
		}
	}
	
	.official-product {
		padding-top: 292px;
		position: relative;
		height: 572px;
		background-color: #0090FF;
		
		.watermark {
			color: rgb(250, 250, 250, .2);
		}
		.title {
			color: #fff;
		}
		.official-product-bg {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			>img {
				display: block;
				width: 100%;
				height: 100%;
				opacity: .4;
			}
		}
		.official-product-tabbar {
			position: absolute;
			top: 186px;
			width: 320px;
			height: 40px;
			left: 0;
			right: 0;
			margin: 0 auto;
			.official-product-tabbar-item {
				float: left;
				width: 130px;
				margin-right: 60px;
				font-size: 18px;
				line-height: 17px;
				text-align: center;
				color: #fff;
				cursor: pointer;
				user-select: none;
			}
			.official-product-tabbar-item:last-child {
				margin-right: 0;
			}
			.official-product-tabba-slide {
				position: absolute;
				bottom: 5px;
				width: 120px;
				height: 2px;
				background-color: #fff;
				transition: transform .2s;
			}
			.official-product-tabbar-wap-slide {
				display: none;
			}
		}
	
		.official-product-swiper {
			overflow: hidden;
			position: relative;
			z-index: 2;
			margin: 0 auto 0;
			width: 1200px;
			height: 180px;
			background-color: #fff;
			.official-product-swiper-list {
				width: 2400px;
				height: 100%;
				//transition: transform .2s;
				.official-product-swiperItem {
					display: inline-block;
					width: 1200px;
					height: 100%;
					.official-product-swiperItem-item {
						position: relative;
						display: inline-block;
						width: 300px;
						height: 100%;
						>img {
							display: block;
							position: absolute;
							left: 0;
							top: 0;
							right: 0;
							bottom: 0;
							margin: auto;
						}
					}
				}
			}
		}
		.official-product-wap-swiper {
			display: none;
		}
	}
	
	.official-promote {
		position: relative;
		height: 672px;
		padding-top: 177px;
		background: url(../../../assets/sys/official/industry_promotion_bg.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		.official-promote-row1 {
			width: 1000px;
			margin: 0 auto;
			.official-promote-item:first-child {
				margin-right: 360px;
			}
		}
		.official-promote-row2 {
			width: 1200px;
			margin: 40px auto 0;
			.official-promote-item:first-child {
				margin-right: 560px;
			}
		}
		.official-promote-item {
			position: relative;
			display: inline-block;
			width: 320px;
			height: 110px;
			background: url(../../../assets/sys/official/industry_promotion_frame.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			.official-promote-item-name {
				position: absolute;
				top: 29px;
				left: 29px;
				font-size: 24px;
				line-height: 23px;
				color: #333;
			}
			.official-promote-item-subTitle {
				position: absolute;
				left: 30px;
				top: 66px;
				font-size: 14px;
				line-height: 14px;
				color: #666;
			}
	
			.official-promote-item-img {
				position: absolute;
				width: 46px;
				height: 44px;
				top: 33px;
				left: 178px;
			}
			.official-promote-item-value {
				position: absolute;
				top: 37px;
				left: 234px;
			}
			.official-promote-item-num {
				font-size: 40px;
				line-height: 37px;
				font-family: Bebas;
				color: #FF003C;
			}
			.official-promote-item-unit {
				font-size: 24px;
				line-height: 37px;
				font-family: Bebas;
				color: #FF003C;
			}
		}
	}
	
	.official-marke {
		position: relative;
		height: 682px;
		background-color: #F0F4F8;
		background-repeat: no-repeat;
		background-size: 100% 100%;
		.official-marke-list {
			overflow: hidden;
			position: absolute;
			left: 0;
			right: 0;
			top: 187px;
			width: 1200px;
			margin: 0 auto;
			.official-marke-item {
				position: relative;
				float: left;
				width: 228px;
				height: 405px;
				margin-right: 15px;
				cursor: pointer;
				.official-marke-item-content {
					z-index: 3;
					position: absolute;
					top: 295px;
					left: 20px;
					right: 20px;
					transform: translateY(0);
					transition: all .3s;
					.official-marke-item-serialImage {
						width: 51px;
						height: 60px;
					}
					.official-marke-item-title {
						margin: 0px 0 20px;
						font-size: 24px;
						line-height: 23px;
						color: #fff;
					}
					.official-marke-item-subTitle {
						font-size: 14px;
						line-height: 19px;
						color: #F1F1F1;
					}
				}
				.official-marke-item-overlay {
					position: absolute;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					background-image: url(../../../assets/sys/official/marke-unActive.png);
					background-color: transparent;
					background-repeat: no-repeat;
					background-size: 100% 100%;
					transition: all .3s;
				}
				.official-marke-item-img {
					display: block;
					width: 100%;
					height: 100%;
				}
			}
			.official-marke-item:last-child {
				margin-right: 0;
			}
			.official-marke-item:hover {
				.official-marke-item-overlay {
					position: absolute;
					width: 100%;
					height: 100%;
					left: 0;
					top: 0;
					background-image: none;
					background-color: rgb(0, 96, 255, .8);
				}
				
				.official-marke-item-content {
					transform: translateY(-20px);
				}
			}
		}
		.official-markeWap-list {
			display: none;
		}
	}
	
	
	.official-cooperation {
		position: relative;
		height: 784px;
		background: url(../../../assets/sys/official/cooperation_bg.png);
		background-repeat: no-repeat;
		background-size: 100% 300px;
		background-position: 0 484px;
		.watermark {
			color: rgb(250, 250, 250, .2);
		}
		
		.official-cooperation-content {
			width: 1200px;
			height: 508px;
			position: absolute;
			left: 0;
			right: 0;
			top: 176px;
			margin: 0 auto;
			box-shadow: 0px 20px 40px 0px rgba(0,83,255,0.1);
			background-color: #fff;
			.official-cooperation-form {
				padding-left: 50px;
				width: 800px;
				height: 508px;
				float: left;
			}
			.official-cooperation-desc {
				width: 400px;
				height: 100%;
				float: left;
				background: url(../../../assets/sys/official/business_cooperation_pic.png);
				background-repeat: no-repeat;
				background-size: 100% 100%;
				>div {
					text-align: center;
					color: #fff;
				}
				.official-cooperation-desc-name {
					margin-top: 130px;
					font-size: 24px;
					line-height: 22px;
					font-weight: 500;
				}
				.official-cooperation-desc-title {
					margin-top: 30px;
					font-size: 34px;
					line-height: 32px;
					font-weight: 500;
				}
				.official-cooperation-desc-prompt1 {
					margin-top: 80px;
					font-size: 16px;
					line-height: 14px;
					font-weight: 300;
				}
				.official-cooperation-desc-prompt2 {
					margin-top: 18px;
					font-size: 16px;
					line-height: 14px;
					font-weight: 300;
				}
			}
			.ant-form-item {
				margin-bottom: 20px;
			}
			.ant-form-item-label {
				width: 80px;
			}
			.official-cooperation-form-input {
				.ant-form-item-control-wrapper {
					width: 520px;
				}
			}
			.official-cooperation-form-checkbox {
				.ant-form-item-control-wrapper {
					width: 550px;
				}
			}
			.ant-form-item-control-wrapper {
				//width: 550px;
				.ant-checkbox-checked .ant-checkbox-inner {
					background-color: #0053FF;
					border-color: #0053FF;
				}
				.ant-input, .ant-cascader-picker, .ant-cascader-input, .ant-select-selection {
					border-radius: 4px;
					background-color: #F0F4F8;
					svg {
						color: #fff;
					}
					.ant-cascader-picker-clear {
						background-color: transparent;
					}
				}
				.ant-input:focus, .ant-cascader-picker:focus .ant-cascader-input, .ant-select-selection:focus {
					border-color: #fff;
					box-shadow: 0 0 0 2px rgba(255, 255, 255, 0.2);
				}
				.ant-input::-webkit-input-placeholder, .ant-select-selection__placeholder {
					color: #F8A3AD;
				}
			}
		}
		.official-cooperation-form-error {
			position: absolute;
			left: 0;
			bottom: -38px;
			font-size: 14px;
			color: #FF003C;
		}
		.official-cooperation-submit {
			display: block;
			width: 600px;
			height: 48px;
			margin-top: 20px;
			font-size: 16px;
			text-align: center;
			line-height: 48px;
			border-radius: 4px;
			border: none;
			color: #fff;
			background-color: #0053FF;
			outline: none;
			cursor: pointer;
		}
	}
	
	.official-footer {
		position: relative;
		height: 301px;
		background-color: #333;
		.official-footer-content {
			position: relative;
			margin: 0 auto;
			width: 1200px;
			height: 100%;
		}
		.official-footer-phone {
			position: absolute;
			top: 110px;
			left: 0;
			font-size: 14px;
			color: #aaa;
		}
		.official-footer-email {
			position: absolute;
			left: 0;
			top: 88px;
			color: #aaa;
			font-size: 16px;
		}
		.official-footer-address {
			position: absolute;
			top: 138px;
			left: 0;
			font-size: 14px;
			color: #aaa;
		}
		.official-footer-company {
			position: absolute;
			bottom: 0px;
			left: 0;
			right: 0;
			height: 62px;
			border-top: 1px solid #4a4a4a;
			text-align: center;
			line-height: 61px;
			font-size: 13px;
			color: #888888;
		}
		.official-footer-logo {
			position: absolute;
			left: 0;
			width: 250px;
			height: 36px;
			top: 60px;
		}
		.official-footer-wechat {
			position: absolute;
			right: 0;
			top: 60px;
			overflow: hidden;
			.official-footer-wechat-item {
				float: right;
				width: 116px;
				margin-left: 20px;
				>img {
					width: 116px;
					height: 116px;
				}
				>div {
					margin-top: 10px;
					font-size: 12px;
					color: #fff;
					text-align: center;
				}
			}
		}
	}
	
	@media screen and (max-width: 768px) {
		
		.official-navbar-up {
			display: none;
		}
		.official-navbar-next {
			opacity: 1 !important;
		}
		.official-navbar  {
			width: 7.5rem;
			overflow: hidden;
			position: fixed;
			height: 0.9rem;
			background-color: #fff;
		}
		.official-navbar-logo {
			left: 0.3rem;
			top: 0.25rem;
			width: 1.82rem;
			height: 0.26rem;
		}
		.official-navbar-tabbar {
			display: none;
		}
	}
	
	.official-dialog {
		padding: 18px 0 16px;
		>img {
			display: block;
			width: 70px;
			height: 68px;
			margin: 0 auto;
		}
		.official-dialog-content {
			margin: 19px 0;
			font-size: 20px;
			text-align: center;
			line-height: 19px;
			color: #333;
		}
		.official-dialog-subTitle {
			font-size: 16px;
			text-align: center;
			line-height: 16px;
			color: #666;
		}
		.official-dialog-btn {
			display: block;
			width: 170px;
			height: 46px;
			margin: 31px auto 0;
			line-height: 46px;
			font-size: 16px;
			text-align: center;
			outline: none;
			border: none;
			background-color: #0053FF;
			color: #fff;
			cursor: pointer;
			user-select: none;
			transition: all .2s;
		}
		.official-dialog-btn:hover {
			background-color: #006DFF;
		}
		.official-dialog-btn:active {
			background-color: #0054C5;
		}
	
	}
	.official-wap-anchor {
		display: none;
	}
}


@media screen and (max-width: 768px) {
	.official {
		padding-top: 0.9rem;
	}
	.official-navbar {
		z-index: 9;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		height: 0.9rem;
		opacity: 1 !important;
		background-color: #fff;
	}
	.official-navbar-logo {
		position: absolute;
		left: 0.3rem;
		top: 0;
		bottom: 0;
		width: 2.82rem;
		height: 0.4rem;
		margin: auto 0;
		background-image: url(../../../assets/sys/official/top_logo_red.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
	.official-navbar-tabbar {
		display: none;
	}
	.official-navbar-menu {
		position: absolute;
		width: 0.3rem;
		height: 0.27rem;
		right: 0.3rem;
		top: 0;
		bottom: 0;
		margin: auto 0;
		background-image: url(../../../assets/sys/official/wap_more.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
	}
	.official-header {
		position: relative;
		height: 3.6rem;
		background-image: url(../../../assets/sys/official/wap_banner.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		.official-header-title {
			position: absolute;
			left: 0;
			right: 0;
			top: 1.33rem;
			text-align: center;
			font-size: 0.4rem;
			color: #fff;
		}
		.official-header-subTitle {
			position: absolute;
			top: 2.01rem;
			left: 0;
			right: 0;
			text-align: center;
			font-size: 0.26rem;
			font-weight: 100;
			color: #fff;
		}
		.official-header-mask {
			display: none;
		}
	}
	.title {
		padding-top: 0.8rem;
		font-size: 0.4rem;
		line-height: 0.38rem;
		text-align: center;
	}
	.official-about {
		position: relative;
		padding: 0 0.4rem 1rem 0;
		.aboutList {
			margin-top: 0.59rem;
			height: 8.28rem;
			.aboutItem {
				width: 3.15rem;
				height: 4.14rem;
				margin-left: 0.4rem;
				float: left;
				border-radius: 0.1rem;
				box-shadow: 0px 0.2rem 0.4rem 0px rgba(0,83,255,0.1);
				.aboutItem-image {
					display: block;
					margin: 0.34rem auto;
					width: 1.72rem;
					height: 1.56rem;
				}
				.aboutItem-title {
					font-size: 0.3rem;
					line-height: 0.29rem;
					text-align: center;
					color: #242831;
				}
				.aboutItem-subTitle {
					padding: 0.16rem 0.27rem 0 0.28rem;
					font-size: 0.22rem;
					text-align: center;
					line-height: 0.34rem;
					color: #535A64;
					zoom: 0.9166666;
				}
			}
		}
	}
	.official-marke {
		position: relative;
		padding-bottom: 1rem;
		background-color: #F0F4F8;
		.official-marke-list {
			display: none;
		}
		.official-markeWap-list {
			margin-top: 0.6rem;
			.official-marke-item {
				position: relative;
				width: 6.7rem;
				height: 4.05rem;
				margin: 0.4rem auto 0;
				.official-marke-item-img {
					width: 100%;
					height: 100%;
				}
				.official-marke-item-title {
					z-index: 2;
					position: absolute;
					left: 0.4rem;
					bottom: 0.81rem;
					font-size: 0.36rem;
					line-height: 0.34rem;
					color: #fff;
				}
				.official-marke-item-subTitle {
					z-index: 2;
					position: absolute;
					left: 0.4rem;
					bottom: 0.4rem;
					font-size: 0.22rem;
					line-height: 0.21rem;
					color: #fff;
					zoom: 0.9166666;
				}
				.official-marke-item-overlay {
					position: absolute;
					left: 0;
					top: 0;
					right: 0;
					bottom: 0;
					background-image: url(../../../assets/sys/official/wap_platform_introduction_mask.png);
					background-repeat: no-repeat;
					background-size: 100% 100%;
				}
			}
		}
	}

	.official-product {
		position: relative;
		padding-bottom: 1rem;
		background-color: #0090FF;
		.title {
			color: #fff;
		}
		.official-product-bg {
			position: absolute;
			left: 0;
			top: 1.08rem;
			width: 7.5rem;
			height: 6.4rem;
			background-image: url(../../../assets/sys/official/wap_support_platform_bg.png);
			background-repeat: no-repeat;
			background-size: 100% 100%;
			>img {
				display: none;
			}
		}
		.official-product-tabbar {
			position: relative;
			width: 4rem;
			height: 0.25rem;
			margin: 0.6rem auto 0;
			.official-product-tabbar-item {
				float: left;
				
				font-size: 0.26rem;
				line-height: 0.25rem;
				color: #fff;
			}
			.official-product-tabbar-item:last-child {
				float: right;
				margin-right: 0;
			}
			.official-product-tabba-slide {
				display: none;
			}
			.official-product-tabbar-wap-slide {
				position: absolute;
				width: 1.78rem;
				height: 0.02rem;
				left: 0;
				bottom: -0.15rem;
				background-color: #fff;
				transition: transform .2s;
			}
			
		}
		.official-product-swiper {
			display: none;
		}
		.official-product-wap-swiper {
			width: 6.7rem;
			height: 3.7rem;
			margin: 0.77rem auto 0;
			.official-product-wap-swiperItem {
				.official-product-swiperItem-item {
					position: relative;
					float: left;
					width: 3.3rem;
					height: 1.8rem;
					margin-bottom: 0.1rem;
					background-color: #fff;
					>img {
						position: absolute;
						left: 0;
						right: 0;
						top: 0;
						bottom: 0;
						margin: auto;
					}
				}
				.official-product-swiperItem-item-left {
					margin-right: 0.1rem;
				}
			}
		}
	}

	.official-promote {
		position: relative;
		height: 9.02rem;
		background-image: url(../../../assets/sys/official/wap_industry_promotion_bg.png);
		background-repeat: no-repeat;
		background-size: 100% 100%;
		.title {
			margin-bottom: 0.6rem;
		}
		.official-promote-row2 {
			margin-left: 0.1rem;
		}
		.official-promote-row1 {
			margin-left: 0.4rem;
		}
		.official-promote-row1, .official-promote-row2 {
			float: left;
			width: 3.3rem;
			.official-promote-item {
				position: relative;
				width: 3.3rem;
				height: 1.33rem;
				margin-bottom: 0.1rem;
				background-image: url(../../../assets/sys/official/wap_industry_promotion_frame.png);
				background-repeat: no-repeat;
				background-size: 100% 100%;
				.official-promote-item-name {
					position: absolute;
					left: 0.28rem;
					top: 0.35rem;
					font-size: 0.3rem;
					line-height: 0.28rem;
					color: #333;
				}
				.official-promote-item-subTitle {
					position: absolute;
					left: 0.29rem;
					bottom: 0.3rem;
					font-size: 0.22rem;
					line-height: 0.2rem;
					zoom: 0.9166666;
					color: #666666;
				}
				.official-promote-item-img {
					position: absolute;
					left: 1.77rem;
					top: 0.45rem;
					width: 0.46rem;
					height: 0.44rem;
				}
				.official-promote-item-value {
					position: absolute;
					right: 0.31rem;
					top: 0.32rem;
					height: 0.43rem;
					.official-promote-item-num {
						font-size: 0.46rem;
						line-height: 0.43rem;
						font-family: Bebas;
						color: #FF003C;
					}
					.official-promote-item-unit {
						font-size: 0.3rem;
						line-height: 0.43rem;
						font-family: Bebas;
						color: #FF003C;
					}
				}
			}
			.official-promote-item:first-child {
				margin-left: 0;
			}
		}
		.official-promote-row2 {
			.official-promote-item:first-child {
				.official-promote-item-img {
					left: 1.63rem;
				}
			}
		}
	}

	.official-cooperation {
		position: relative;
		padding-bottom: 1rem;
		.official-cooperation-content {
			padding: 0 0 0 0.55rem;
			.ant-form-item {
				position: relative;
				margin-bottom: 0.32rem;
			}
			.official-cooperation-form-checkbox {
				margin-bottom: 0.2rem;
			}
			.official-cooperation-form-input {
				margin-right: 0;
				width: 6.4rem;
				input {
					background-color: #F0F4F8;
					font-size: 0.3rem;
				}
			}
			.ant-checkbox-group-item {
				margin-right: 0.4rem;
				margin-bottom: 0.32rem;
			}
			.ant-form label {
				font-size: 0.24rem;
			}
	
			.ant-checkbox + span{
				padding: 0 0.2rem 0 0.14rem;
			}
	
			form input[type='checkbox'] {
				width: 0.3rem;
				height: 0.3rem;
			}
			.official-cooperation-form-error {
				position: absolute;
				left: 0;
				bottom: -0.76rem;
				font-size: 0.24rem;
				color: #FF003C;
			}
			.official-cooperation-submit {
				width: 6.4rem;
				height: 0.8rem;
				border-radius: 0.04rem;
				font-size: 0.3rem;
				color: #fff;
				background-color: #0053FF;
			}
		}
		.official-cooperation-desc {
			display: none;
		}
	}

	.official-footer {
		background-color: #222222;
		.official-footer-content {
			position: relative;
			height: 5.86rem;
			.official-footer-phone {
				position: absolute;
				bottom: 1.1rem;
				left: 0.3rem;
				font-size: 0.22rem;
				line-height: 0.22rem;
				zoom: 0.916666;
				color: #888;
			}
			.official-footer-address {
				position: absolute;
				bottom: 0.6rem;
				left: 0.3rem;
				font-size: 0.22rem;
				line-height: 0.22rem;
				zoom: 0.916666;
				color: #888;
			}
			.official-footer-logo {
				position: absolute;
				top: 3.81rem;
				left: 0.3rem;
				width: 2.78rem;
				height: 0.4rem;
			}
			.official-footer-wechat {
				position: absolute;
				left: 0.3rem;
				top: 0.8rem;
			}
			.official-footer-wechat-item {
				float: left;
				margin-right: 0.4rem;
				width: 2rem;
				>img {
					width: 2rem;
					height: 2rem;
				}
				>div {
					margin-top: 0.2rem;
					text-align: center;
					font-size: 0.22rem;
					line-height: 0.22rem;
					zoom: 0.916666;
					color: #fff;
				}
			}
		}
		.official-footer-company {
			height: 1.06rem;
			border-top: 0.02rem solid #4A4A4A;
			font-size: 0.22rem;
			line-height: 1.04rem;
			text-align: center;
			zoom: 0.916666;
			color: #888;
		}
	}

	.ant-modal {
		width: 6.5rem !important;
	}
	.official-dialog {
		img {
			display: block;
			width: 1.4rem;
			height: 1.36rem;
			margin: 0 auto;
		}
		.official-dialog-content {
			margin: 0.19rem 0;
			font-size: 0.32rem;
			text-align: center;
			color: #333;
		}
		.official-dialog-subTitle {
			font-size: 0.24rem;
			text-align: center;
			color: #666;
		}
		.official-dialog-btn {
			display: block;
			width: 2.8rem;
			height: 0.64rem;
			margin: 0.19rem auto 0;
			border: none;
			font-size: 0.28rem;
			color: #fff;
			background-color: #0053FF;
			outline: none;
		}
	}

	.ant-drawer-body {
		padding: 0 !important;
	}

	.ant-drawer-content-wrapper {
		height: auto !important;
	}

	.official-drawer {
		.official-drawer-title {
			position: relative;
			height: 0.9rem;
			padding: 0.25rem 0 0 0.3rem;
			.official-drawer-logo {
				width: 2.82rem;
				height: 0.4rem;
				background-image: url(../../../assets/sys/official/top_logo_red.png);
				background-repeat: no-repeat;
				background-size: 100% 100%;
			}
			.official-drawer-close {
				position: absolute;
				right: 0.3rem;
				width: 0.3rem;
				height: 0.27rem;
				top: 0;
				bottom: 0;
				margin: auto 0;
			}
		}
		.official-drawer-tabbarList {
			padding: 0 0.45rem;
			.official-drawer-tabbarItem {
				display: block;
				height: 1.12rem;
				text-align: center;
				line-height: 1.1rem;
				border-bottom: 0.02rem solid #ECECEC;
				color: #333;
			}
			.official-drawer-tabbarItem-active {
				color: #0053FF;
			}
		}
	}
	.official-wap-anchor {
		position: absolute;
		top: -0.9rem;
	}
}


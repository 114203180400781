html, body {
    width: 100%;
    height: 100%;
    scroll-behavior: smooth;
}
p {
    margin: 0;
}
* {
    box-sizing: border-box;
    font-family: "Noto Sans SC";
    font-weight: 400;
}

@media screen and (min-width: 768px) {
    * {
        font-size: 14px;
    }
}

.ant-menu-submenu-popup .ant-menu-item-selected {
    background-color: #0EB4D2 !important;
}
.ant-menu-submenu-popup .layout-slider-content-menu>span {
    color: #fff;
}
iframe {
    display: none;
}
#root {
    height: 100%;
    color: #333;
}

.radius-default {
    border-radius: 10px;
}
.text_ellipsis {
    overflow : hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1; /* 限制在一个块元素显示的文本的行数 */
    -webkit-box-orient: vertical; /* 垂直排列 */
    word-break: break-all;  /* 内容自动换行 */
  }